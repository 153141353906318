<template>
  <div v-if="imageUri && shouldShow" class="logo pr-3">
    <figure class="image">
      <img :src="imageUri" :alt="name" @error="noImage">
    </figure>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    imageUri: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    shouldShow: true,
  }),
  methods: {
    noImage() {
      this.shouldShow = false;
    },
  },
};
</script>
